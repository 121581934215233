<template>
  <div
    class="form-page"
    v-loading="loading"
    :class="header.isFullscreen ? 'fullscreen' : ''"
    :style="leftBarWidth"
  >
    <el-container v-if="library && library.user && library.user.length" class="layout">
      <!-- 顶部菜单栏 -->
      <el-header class="menu-bar" style="height: 54px">
        <page-header-content @header-click="handleHeaderClick" />
      </el-header>
      <el-container class="workspace">
        <div
          class="workspace-left"
          style="width: auto; max-width: 50%; min-width: var(--left-barwidth)"
        >
          <!-- 左侧菜单 -->
          <div class="resize-bar" />
          <div class="resize-line" />
          <div class="resize-save">
            <!-- 左侧菜单 -->
            <page-left
              v-model="main.selected"
              :componentsList="[]"
            />
          </div>
        </div>
        <el-main class="workspace-middle">
          <page-middle
            v-model="main.selected"
            ref="pageMiddle"
            :page="main.page"
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            @update-list="updateList"
          />
        </el-main>
        <el-aside class="workspace-right" style="width: 360px">
          <page-right
            :selected="main.selected"
            :page="main.page"
            :bottomBtn="main.bottomBtn"
            :list="main.componentList"
            @update-list="updateList"
          />
        </el-aside>
      </el-container>
    </el-container>
    <PreviewCodeDialog v-model="showPreviewqrCode" :data="qrCodeData" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PageMiddle from '@/modules/organization-admin/components/OrgFrame/Page/PageMiddle'
let library = require('../../assets/jsons/component-library.json')
const defaultList =
  require('@/modules/organization-admin/assets/jsons/default-data.json').default
let { page, bottomBtn } = JSON.parse(JSON.stringify(library))

import {
  saveField,
  allField,
  templateField,
  saveTemplate,
  getPreviewCode,
} from '@/modules/organization-admin/api/org-frame/custom-form'
import PageHeaderContent from '@/modules/organization-admin/components/OrgFrame/Page/PageHeaderContent'
import PageLeft from '@/modules/organization-admin/components/OrgFrame/Page/PageLeft'
import PageRight from '@/modules/organization-admin/components/OrgFrame/Page/PageRight'
import PreviewCodeDialog from '@/modules/organization-admin/components/OrgFrame/Page/PreviewCodeDialog'
import {
  fetchConsultSurveyFormConfigs,
  saveConsultSurveyFormConfigs,
} from '../../api/consult-service/survey-list'

export default {
  components: {
    PageMiddle,
    PageRight,
    PageLeft,
    PageHeaderContent,
    PreviewCodeDialog,
  },
  data() {
    return {
      loading: false,
      header: {
        isFullscreen: true,
      },
      main: {
        selected: 'page',
        page: JSON.parse(JSON.stringify(page)),
        bottomBtn: JSON.parse(JSON.stringify(bottomBtn)),
        componentList: [],
      },
      qrCodeData: {},
      showPreviewqrCode: false,
      leftBarWidth: '--left-barwidth: ' + '285px' + '; ',
    }
  },
  computed: {
    ...mapState('formPage', ['library']),
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  created() {
    this.$nextTick(() => {
      // 左侧菜单选项-个人资料表单
      this.getUserFormList(() => {
        this.getSurveyFormConfig() // 中间的表单
      })
    })
  },
  mounted() {
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    // 在即将离开当前页面执行
    window.onbeforeunload = function (e) {
      e = e || window.event; // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "当前页面有修改，是否放弃更改？";
      } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "当前页面有修改，是否放弃更改？";
    };
  },
  methods: {
    // 获取配置详情
    getSurveyFormConfig() {
      fetchConsultSurveyFormConfigs({
        id: this.$route.params.id,
      })
        .then(({ data }) => {
          console.log(data)
          let listData = data?.form_configs || data || [];
          let componentList = []

          listData.forEach((item, index) => {
            componentList.push({
              ...item,
              extra: {
                timestamp: new Date().getTime() + index * index,
              },
            })
            this.updateCount(item)
          })

          this.main.componentList = componentList
          this.main.page = this.$mergeModel(page, data.page_config || {});
        })
        .catch(() => {})
    },
    /**
     * 入会申请表单
     * 写入 library
     */
    getUserFormList(callback) {
      this.loading = true
      allField({
        pageset: 'user',
      })
        .then((res) => {
          const { data } = res
          let componentList = []
          let listData = data.length
            ? data
            : data.configs && data.configs.length
            ? data.configs
            : defaultList
          listData.forEach((item, index) => {
            if (item.field === 'phone') return
            if (item.field === 'member_type') return
            if (item.field === 'referee') return
            if (item.field === 'position_id') return

            componentList.push({
              ...item,
              title: item.props.normal.label, // 实际使用时的名称
              is_user_info: 1, // 是否用户个人表单信息（0 或 1）
              extra: {
                timestamp: new Date().getTime() + index * index,
                count: 0,
                maxCount: 1,
              },
            })
          })

          this.$store.commit(
            'formPage/setLibrary',
            JSON.parse(
              JSON.stringify({
                ...library,
                user: JSON.parse(JSON.stringify(componentList)),
              })
            )
          )

          this.loading = false

          callback()
        })
        .catch(() => {
          this.loading = false
        })
    },
    async handleHeaderClick(key) {
      switch (key) {
        case 'save': {
          try {
            await this.save()
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'preview':
        case 'fullscreen':
        default:
      }
    },
    /**
     * 更新组件列表
     */
    updateList(arr) {
      this.main.componentList = arr
    },
    // 保存
    save(isPreview = false) {
      this.loading = true
      const saveData = { ...this.main }
      const fieldData = {
        id: this.$route.params.id
      }
      let flag = false
      // 表单标题查重
      saveData.componentList.forEach((el1, i) => {
        if (el1.is_user_info === undefined) {
          el1.is_user_info = 0
        }

        if (el1.question_id === undefined) {
          el1.question_id = ''
        }

        if (el1.field === 'member_type') {
          el1.props.normal.options.forEach((op) => {
            if (op.is_open && op.option_name === '' && !flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('会员类型选项文本不能为空')
              this.loading = false
              flag = true
            }
          })
        }
        saveData.componentList.forEach((el2, j) => {
          if (
            (el2.props.normal.label || el2.props.title) ===
              (el1.props.normal.label || el1.props.title) &&
            i !== j
          ) {
            if (!flag) {
              this.main.selected = i
              this.$refs.pageMiddle.errFormConfig(i)
              this.$message.error('存在标题重复的表单')
              this.loading = false
              flag = true
            }
          }
        })
      })
      if (flag) {
        // 查重不通过，走 catch
        return Promise.reject()
      }
      // 预览不需要保存，仅查重
      if (isPreview) return Promise.resolve()
      fieldData.form_configs = JSON.stringify(saveData.componentList)

      fieldData.page_config = JSON.stringify(saveData.page)

      console.log(saveData.componentList)
      saveConsultSurveyFormConfigs(fieldData)
        .then((res) => {
          if (!isPreview) {
            this.$message.success(res.msg)
            this.loading = false
          }
          return Promise.resolve()
        })
        .catch(() => {
          this.loading = false
          return Promise.reject()
        })
    },
    /**
     * 修改组件使用次数
     * @param{String} field 组件标识
     */
    updateCount({field, is_user_info, props}) {
      let updated = false
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library))
      const groups = ['survey', 'user']
      groups.forEach((g) => {
        if (newLibrary[g])
          newLibrary[g].forEach((li) => {
            if (updated) return
            if (field) {
              if (li.field === field) {
                if (is_user_info) {
                  if (li.title === props.normal.label) {
                    li.extra && li.extra.count++
                    updated = true
                  }
                } else {
                  li.extra && li.extra.count++
                  updated = true
                }
              }
            }
          })
      })
      this.$store.commit('formPage/setLibrary', newLibrary)
    },
  },
}
</script>

<style lang="scss">
.form-page {
  overflow-x: auto;
  margin: calc(-#{$space} * 2);
  background-color: #eef2f8;

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;

    .workspace {
      height: calc(100vh - 54px);
    }
  }

  .menu-bar {
    background-color: $primary;
  }

  /* 顶部菜单栏下面的一整个容器 */
  .workspace {
    height: calc(100vh - 60px - 50px - 54px);
  }

  .page-left,
  .page-right {
    background-color: white;
  }

  .workspace-left {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    float: left;
  }

  .el-main.workspace-middle {
    padding: 0;
    overflow: hidden;
  }
}

.resize-save {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.resize-bar {
  width: var(--left-bar-width);
  min-width: var(--left-bar-width);
  max-width: 100%;
  /*height: inherit;*/
  height: calc(100vh - 54px);
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}

/* 拖拽线 */
.resize-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #eee;
  border-left: 1px solid #bbb;
  pointer-events: none;
}

.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  border-left: 1px dashed #3a76ff;
}

.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: inherit;
}

/* Firefox只有下面一小块区域可以拉伸 */
@supports (-moz-user-select: none) {
  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px solid #bbb;
  }
  .resize-bar:hover ~ .resize-line::after,
  .resize-bar:active ~ .resize-line::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: -8px;
    /*background: url(./resize.svg);*/
    /*background-size: 100% 100%;*/
  }
}
</style>
