import api from '@/base/utils/request'

export const getSurveyIndex = (data) => {
  return api({
    url: '/admin/consult/survey/index',
    method: 'post',
    data,
  })
}
export const setServiceConfig = (data) => {
  return api({
    url: '/admin/consult/settings/saveServiceConfig',
    method: 'post',
    data,
  })
}
export const softDelete = (data) => {
  return api({
    url: '/admin/consult/survey/softDelete',
    method: 'post',
    data,
  })
}
export const Delete = (data) => {
  return api({
    url: '/admin/consult/survey/del',
    method: 'post',
    data,
  })
}
export const putBack = (data) => {
  return api({
    url: '/admin/consult/survey/putBack',
    method: 'post',
    data,
  })
}
export const addSurvey = (data) =>{
    return api({
        url: '/admin/consult/survey/save',
        method: 'post',
        data,
      }) 
}

export const fetchConsultSurveyFormConfigs = (data) => {
  return api({url:'/admin/consult/survey/getFormConfigs', method: 'post', data})
}

export const saveConsultSurveyFormConfigs = (data) => {
  return api({url:'/admin/consult/survey/saveFormConfigs', method: 'post', data})
}

export const fetchReplyTypes = (data) => {
  return api({url:'/admin/consult/serviceReply/types', method: 'post', data})
}